<template>
    <div>
      <app-bar title="Administration" />
      <v-container>
        <v-row>
            <v-col>
                <v-btn @click="republish()">
                    <v-icon left color="grey">mdi-cloud-refresh</v-icon>
                    Alle veröffentlichten Datensätze synchronisieren
                </v-btn>
            </v-col>
        </v-row>
      </v-container>
      
    </div>
</template>
  
<script>
import AppBar from "../components/views/AppBar.vue";
import CarDataService from "../services/CarDataService";

export default {
    name: "administration",
    created() {
    },
    data() {
        return {

        }
    },
  computed: {
  },
  methods: {
    republish() {
      CarDataService.republishPublishedCars().then(res => {
        if(res && res.success) {
            this.$notify({
                title: 'Datensätze erneut veröffentlicht',
                text: 'Die Datensätze wurden erfolgreich veröffentlicht.',
                type: 'success'
            });
        }else {
            this.$notify({
                title: 'Fehler beim Veröffentlichen der Datensätze',
                text: 'Beim Veröffentlichen der Datensätze ist ein Fehler aufgetreten.',
                type: 'error'
            });
        }
      })
    },

  },
  components: {
    AppBar,    
  },
};
</script>  